import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col , Form, FormGroup, Label, Input ,Alert } from 'reactstrap'
import { navigate } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
function serializeKey(data) {
	var formBody = [];
	for (var property in data) {
	  var encodedKey = encodeURIComponent(property);
	  var encodedValue = encodeURIComponent(data[property]);
	  formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");
	return formBody;
}

export default class TeklifistePage extends React.Component {
  constructor(props) {
		super(props); // super arguman geçmenizi sağlar eğer constructor kullanmak isterseniz kullanmak zorunlu oluyor.

    this.state = { // burası bind da kullandığım değerler
      adiniz: "",
      firmadi: "",
      email: "",
      telefon: "",
      sitetipi: "",
      islevsellik: "",
			successgame:"hidegame",
			dangergame:"hidegame",
			isEnabled :"1",
  		};
  	}


  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name


    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()

		this.setState({dangergame: "hidegame"});
				this.setState({successgame: "hidegame"});
//  alert(`adi: ${this.state.adiniz} firmaadi : ${this.state.firmadi}! email: ${this.state.email} telefon : ${this.state.telefon} sitetipi: ${this.state.sitetipi}  islevsellik: ${this.state.islevsellik} `)
    var name = this.state.adiniz;
    var firmadi = this.state.firmadi;
    var email = this.state.email;
    var telefon = this.state.telefon;
    var sitetipi = this.state.sitetipi;
    var islevsellik = this.state.islevsellik;



if (name.length < 3 || email.length < 5 || telefon.length < 5) {
	this.setState({dangergame: "showgame"});
} else
{

	    // react native ajax komutu
	    fetch('https://www.istanbulwebtasarim.com.tr/demo/cloudmining/patkut/testere.php', { // extralar
	      method: 'POST',
	      headers: {
	      			    'Content-Type': 'application/x-www-form-urlencoded',
	      			},

	            body:serializeKey({ // değerleri serialize ediyoruz
				adiniz: name, firmadi: firmadi ,  email: email, telefon: telefon, sitetipi: sitetipi, islevsellik: islevsellik
				})
	  		})
	      .then((res) => res.json()) // gelen datayı parse ediyoruz
	      .then((res) => {
	          //  console.log(res);
	        if (res.result === 1){
					this.setState({successgame: "showgame"});
		this.setState({isEnabled: "0"});
					setTimeout(
	    function() {
	        	navigate("/")
	    },3300
	);


						  //navigate("/")
	}    else {
						this.setState({dangergame: "showgame"});

	    }  })
	      .catch((error) => {
				this.setState({dangergame: "showgame"});
	      });
}


    }


  render() {

		const data = this.props.data
				const pagebgteklifiste = data.pagebgteklifiste.childImageSharp.fluid
    return (
      <Layout pageTitle="Web Tasarım Teklif İste">
			<SEO title="Web Tasarım Teklif İste" keywords={[`Teklif iste`, `Web Tasarım`, `Web Tasarım Fiyatları`]} description="Web Tasarım Teklifi alarak kaliteli,responsive web sitesi fiyatını öğrenebilirsiniz." />


        <section className="bginpage">
				<Img fluid={pagebgteklifiste}
				style={{
											 position: 'absolute',
											 left: 0,
											 top: 0,
											 width: '100%',
											 height: '100%',
										 }} alt="Alan Adı Barındırma"/>
        <Container>
          <Row>
            <Col md="5">
            <div className="bginpagedark">
            <h1 className="mb20  section-title">
            Teklif İste
                 </h1>
                 <p>İhtiyaç duyduğunuz ve yarının teknolojileri ile hazırlanacak olan yazılımlarımız ile tanışmak için hazır olun !</p>
          </div>  </Col>
            </Row>
            </Container>
      </section>

      <section className="maincontent">
      <Container>

        <Row>
          <Col md="12" className="acik">
          <h1 className="font-size-normal  section-title">
                   <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
                   Alan Adı Barındırma ( Hosting )
                    <div className="bar"></div>
               </h1>

							 			<div className={this.state.successgame}>
										<Alert color="success">
			 Teklif İsteminiz başarı ile Gönderilmiştir ! Anasayfaya yönlendiriliyorsunuz.
		 </Alert>
							 			</div>

										<div className={this.state.dangergame}>
										<Alert color="danger">
			Bazı Şeyler Yanlış gitti ! Lütfen Formu doğru şekilde doldurarak, tekrardan teklif isteminde bulununuz.
		 </Alert>
										</div>

                     <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="4">
                        <FormGroup>
                         <Label for="adiniz">Adınız*</Label>
                         <Input type="text" name="adiniz" id="adiniz" minLength="3"  value={this.state.adiniz}
                          onChange={this.handleInputChange} validate="true" placeholder="Adınız"  />
                       </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                         <Label for="firmadi">Firma adınız*</Label>
                         <Input type="text" name="firmadi" id="firmadi" value={this.state.firmadi}
                          onChange={this.handleInputChange} placeholder="Firma Adı" />
                       </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                         <Label for="email">e-Mail Adresiniz*</Label>
                         <Input type="text" name="email" id="email" minLength="3" value={this.state.email}
                          onChange={this.handleInputChange} validate="true" placeholder="E-Mail Adresiniz" />
                       </FormGroup>
                        </Col>
                      </Row>

                      <Row>

                      <Col md="6">
                      <FormGroup>
                       <Label for="telefon">Telefon Numaranız*</Label>
                       <Input type="text" name="telefon" id="telefon" minLength="7" value={this.state.telefon}
                        onChange={this.handleInputChange} validate="true" placeholder="Telefon Numaranız" />
                     </FormGroup>
                      </Col>
                      <Col md="6">
                     <FormGroup>
       <Label for="sitetipi">Site Tipi*</Label>
       <Input type="select" name="sitetipi" id="sitetipi" value={this.state.sitetipi} onChange={this.handleInputChange} >
         <option>E-ticaret (alışveriş sepeti, POS)</option>
         <option>Basit içerik (şirket & ürün tanıtımı)</option>
         <option>Diğer</option>
       </Input>
     </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                      <FormGroup>
         <Label for="islevsellik">İşlevsellik ve örnek site</Label>
         <Input type="textarea" name="islevsellik" id="islevsellik" minLength="15" validate="true" value={this.state.islevsellik} onChange={this.handleInputChange}  />
       </FormGroup>
                      </Col>
                    </Row>


                       <button type="submit" className="btn btn-primary text-center" disabled={this.state.isEnabled === "0" ? true : false}>Teklif İste </button>
                     </Form>
        </Col>
        </Row>
        </Container>
        </section>
      </Layout>

    )
  }
}
export const pageQuery = graphql`
query {
pagebgteklifiste: file(
 relativePath: { regex: "/bg15.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
